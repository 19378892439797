import {Routes,Route} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Login from '../Pages/Login/Login';
import Home from '../Pages/Home/Home';
import HelpCenterIndex from '../Pages/HelpCenter/HelpCenterIndex';
import ProviderRegister from '../Pages/Provider/ProviderRegister';
import ProviderIndex from '../Pages/Provider/ProviderIndex';
import ProviderInvitation from '../Pages/Client/ProviderInvitation';
import PendingOrders from '../Pages/Provider/PendingOrders';
import RolesPermissions from '../Pages/Users/RolesPermissions';
import UserIndex from '../Pages/Users/UserIndex';
import HelpCenterAdmin from '../Pages/HelpCenter/HelpCenterAdmin';
import ProviderInvoices from '../Pages/Provider/ProviderInvoices';
import Invoices from '../Pages/Admin/Invoices';
import {ParameterConfig} from '../Pages/ParameterConfig/ParameterConfig'
import RecoverPassword from '../Pages/Login/RecoverPassword';
import Orders from '../Pages/Admin/Orders';
import ProviderInvoiceComplements from '../Pages/Provider/ProviderInvoiceComplements';
import InvoiceComplements from '../Pages/Admin/InvoiceComplements';
import ProviderPayments from '../Pages/Provider/ProviderPayments';
import Payments from '../Pages/Admin/Payments';


export default function Routers(){

    return(
        <Routes>
            <Route path = "/portal" exact element={<PrivateRoute>
                <Login/>
            </PrivateRoute>} />
            <Route path = "/" exact element={<PrivateRoute>
                <Login/>
            </PrivateRoute>} />
            <Route path = "/portal/login" exact element={<Login/>} />
            <Route path = "/portal/forgotPassword" exact element={<RecoverPassword/>} />
            <Route path = "/portal/home" exact element={<PrivateRoute>
                <Home/>
            </PrivateRoute>} />
            <Route path = "/portal/providers" exact element={<PrivateRoute>
                <ProviderIndex/>
            </PrivateRoute>} />
            <Route path = "/portal/invitation/provider" exact element={<PrivateRoute>
                <ProviderInvitation/>
            </PrivateRoute>} />
            <Route path = "/portal/providers/pendingOrders" exact element={<PrivateRoute>
                <PendingOrders/>
            </PrivateRoute>} />
            <Route path = "/portal/providers/invoices" exact element={<PrivateRoute>
                <ProviderInvoices/>
            </PrivateRoute>} />
            <Route path = "/portal/invoices" exact element={<PrivateRoute>
                <Invoices/>
            </PrivateRoute>} />
            <Route path = "/portal/orders" exact element={<PrivateRoute>
                <Orders/>
            </PrivateRoute>} />
            <Route path = "/portal/helpCenter" exact element={<PrivateRoute >
                <HelpCenterIndex/>
            </PrivateRoute>} />
            <Route path = "/portal/helpCenterAdmin" exact element={<PrivateRoute >
                <HelpCenterAdmin/>
            </PrivateRoute>} />
            <Route path = "/portal/permissions" exact element={<PrivateRoute>
                <RolesPermissions/>
            </PrivateRoute>} />
            <Route path = "/portal/users" exact element={<PrivateRoute>
                <UserIndex/>
            </PrivateRoute>} />
            <Route path = "/portal/parameter-config" exact element={
                <PrivateRoute>
                    <ParameterConfig/>
                </PrivateRoute>
            } />
            <Route path = "/portal/provider/invoiceComplements" exact element={<PrivateRoute>
                <ProviderInvoiceComplements/>
            </PrivateRoute>} />
            <Route path = "/portal/provider/payments" exact element={<PrivateRoute>
                <ProviderPayments/>
            </PrivateRoute>} />
            <Route path = "/portal/invoiceComplements" exact element={<PrivateRoute>
                <InvoiceComplements/>
            </PrivateRoute>} />
            <Route path = "/portal/payments" exact element={<PrivateRoute>
                <Payments/>
            </PrivateRoute>} />
            
            <Route path = "/portal/provider/register/:token" exact element={<ProviderRegister/>} />
        </Routes>
    )

}