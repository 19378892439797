import React, { Fragment, useEffect, useState } from "react";
import MenuComponent from "../../Components/Menu/Menu";
import DataTable from "../../Components/DataTable/DataTable";
import { Backdrop, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fab, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Radio, RadioGroup, TextField, Tooltip, Typography } from "@mui/material";
import { AddOutlined, CloseOutlined, FilterListOutlined, Save, Visibility } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import FloatAlert from "../../Components/Alert";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dateFormatter from "../../Utils/DateFormatter";
import { InvoiceService } from "../../Services/InvoiceService";
import moment from "moment-timezone";

const InvoiceComplements = () =>{

    const [loadingBackDrop,setLoadingBackDrop] = useState(false);
    const [loading,setLoading] = useState(false);
    const [btnUploadXml,setBtnUploadXml] = useState("");
    const [btnUploadPdf,setBtnUploadPdf] = useState("");
    const [xmlFile,setXmlFile] = useState(null);
    const [pdfFile,setPdfFile] = useState(null);
    const [open,setOpen] = useState(false);
    const [openErrors,setOpenErrors] = useState(false);
    const[invoiceComplements,setInvoiceComplements] = useState([]);
    const[errors,setErrors] = useState([]);
    const [filters, setShowFilters] = useState(false);
    const dispatch = useDispatch();
    const username = window.sessionStorage.getItem('username');
    const userId = window.sessionStorage.getItem('userId');
    const [filterType,setFilterType] = useState("X");
    const [appliedFilter, setAppliedFilter] = useState(false);
    const [dateType,setDateType] = useState("FP");
    const [selectedComplement, setSelectedInvoice] = useState(false);
    const [invoiceComplementDetails,setInvoiceComplementDetails] = useState([]);
    const [openDetails, setOpenDetails] = useState(false);
    let initialFilters = {
        from: '',
        to: '',
    }

    let filter = `{
        "order": "paymentDate DESC",
        "where": {
            "userId": ${userId}
        },
        "include": ["invoice"]
    }`;


    const[dateFilter,setDateFilter] = useState(initialFilters);

    let initialParams = {
        providerId: username,
        invoiced: filterType,
        fromDate: '',
        toDate: ''
    }

    useEffect(() =>{
        let params = {
            filter: filter
        }
        // setShowFilters(true);
        loadComplements(params);
    },[])


    const loadComplements = (params) =>{
        setLoadingBackDrop(true);
        InvoiceService.getInvoiceComplements(params,(response,error) => {
            setLoadingBackDrop(false);
            if(response && !error)
                setInvoiceComplements(response);
        })
    }

    function filterOrders (){
        return(
            <Fragment>
                <Button onClick={() =>{
                    setShowFilters(true);
                }} startIcon={<FilterListOutlined />} >FILTROS</Button>
                {
                    appliedFilter ?
                    <Tooltip title="Limpiar Filtro">
                        <IconButton onClick={() =>{
                            setLoadingBackDrop(true);
                            setAppliedFilter(false);
                            loadComplements({filter});
                            setDateFilter(initialFilters);
                        }} aria-label="clear" size="small" color="primary">
                            <CloseOutlined />
                        </IconButton>
                    </Tooltip>
                    : ''
                }
                

            </Fragment>
        )
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 100},
        { field: 'folio', headerName: 'Folio', width: 150,valueGetter: (params) =>{
            return params.row.invoice.serie + params.row.invoice.folio;
        },
        },
        { field: 'sapInvoiceId', headerName: 'Complemento SAP', width: 150,
            valueGetter: (params) =>{
                return params.row.invoice.sapInvoiceId;
            },
        },
        { field: 'emisorRfc', headerName: 'RFC Emisor', width: 150,
            valueGetter: (params) =>{
                return params.row.invoice.emisorRfc;
            },
        },
        { field: 'emisor', headerName: 'Emisor', width: 150,
            valueGetter: (params) =>{
                return params.row.invoice.emisor;
            },
        },
        { field: 'documentType', headerName: 'Tipo', width: 150,
            valueGetter: (params) =>{
                return "Pago";
            },
        },
        { field: 'fiscalFolio', headerName: 'UUID', width: 350,
            valueGetter: (params) =>{
                return params.row.invoice.fiscalFolio;
            },
        },
        { field: 'paymentCurrency', headerName: 'Moneda', width: 100},
        { field: 'paymentDate', headerName: 'F. Pago', width: 200,
            valueGetter: (params) =>{
                return params.row.paymentDate.substring(0,19).replace('T',' ');
                // return moment.tz(params.row.paymentDate,'America/Mexico_City').format("YYYY-MM-DD HH:mm:ss");
            },
        },
        { field: 'stampDate', headerName: 'F. Timbrado', width: 200,
            valueGetter: (params) =>{
                return params.row.invoice.stampDate.substring(0,19).replace('T',' ');
                // return moment.tz(params.row.invoice.stampDate,'America/Mexico_City').format("YYYY-MM-DD HH:mm:ss");
            },
        },
        { field: 'totalAmount', headerName: 'Monto Pago', width: 100},
        { field: 'tranlatedTaxImport', headerName: 'IVA', width: 100},
        { field: 'deteinedTaxImport', headerName: 'Imp. Retenidos', width: 150},
        // { field: 'total', headerName: 'Total', width: 150},
        { field: 'F. Alta', headerName: 'Fecha Alta', width: 170,
            valueGetter: (params) =>{
                // return params.row.creationDate.substring(0,10).replace('T',' ');
                return moment.tz(params.row.creationDate,'America/Mexico_City').format("YYYY-MM-DD HH:mm:ss");
            },
        },
        // { field: 'dueDate', headerName: 'F. Vencimiento', width: 170,
        //     valueGetter: (params) =>{
        //         return params.row.dueDate?.substring(0,10);
        //     },
        // },
        { field: 'status', headerName: 'Estatus', width: 170,
            valueGetter: (params) =>{
                let status = "";
                switch(params.row.status){
                    case 0:
                        status = "CANCELADO"
                        break;
                    case 1:
                        status = "PROCESADO"
                        break;
                    
                    
                }
                return status
            },
        },
         {
            field: '',
            headerName: 'Opciones',
            description: 'This column has a value getter and is not sortable.',
            width: 160,
            renderCell: (cellValues) => {
                return (
                    <React.Fragment>
                         <Tooltip title="Ver detalles">
                            <IconButton aria-label="edit" 
                                onClick={(event) => {
                                    showInvoiceComplementDetails(event,cellValues);
                                }}
                                >
                                    <Visibility />
                            </IconButton>
                        </Tooltip>
                        
                    </React.Fragment>
                );
            }
            
        },
    ];

    const columnsDetails = [
        // { field: 'id', headerName: 'ID', width: 100},
        { field: 'invoiceUuid', headerName: 'UUID Factura', width: 400},
        { field: 'partiality', headerName: 'No. Parcialidad', width: 150},
        { field: 'previousBalance', headerName: 'Saldo Ant.', width: 150},
        { field: 'amountPaid', headerName: 'Monto Pagado', width: 150},
        { field: 'paymentCurrency', headerName: 'Moneda P.', width: 100},
        { field: 'exchangeRate', headerName: 'T. Cambio', width: 100},
        { field: 'outstandingBalance', headerName: 'Saldo Insoluto', width: 150},
        
        
    ];

    const handleCaptureXml = ({ target }) => {
        setBtnUploadXml(target.files[0].name)
        setXmlFile(target.files[0]);
    };

    const handleCapturePdf = ({ target }) => {
        setBtnUploadPdf(target.files[0].name)
        setPdfFile(target.files[0]);
    };

    const handleClose = (e,reason) =>{
        if(!reason){
            setOpen(false);
            setBtnUploadXml("");
            setBtnUploadPdf("");
            setXmlFile(null);
            setPdfFile(null);
        }

    }
    const handleCloseErrors = (e,reason) =>{
        if(!reason){
            setOpenErrors(false);
            setErrors([]);
        }

    }

    const openUploadInvoiceComplement = () =>{
        setOpen(true);
    }

    const getClassName = (params) => {
        if (params.field === "status") {
          return params.value === "PROCESADO" ? "cell-green" : "cell-red";
        }
        return "";
    }

    const uploadInvoiceComplement = () =>{
        setLoading(true);
        const formData = new FormData();
        formData.append("file", xmlFile);    
        formData.append("file", pdfFile);    
        formData.append("userId",userId);
        InvoiceService.uploadInvoiceComplement(formData,username,(response,error) =>{
            setLoading(false);
            if(response && !error){
                if(response.success){
                    let props = {open: true,
                        severity: 'success',
                        message: `Complemento cargado correctamente`};
                    dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
                    handleClose();
                    loadComplements({filter: getComplementParams()});

                }
                else{
                    handleClose();
                    setErrors(response.errors);
                    setOpenErrors(true);
                }
            }
            else{
                let props = {open: true,
                    severity: 'error',
                    message: error.message}
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
            }
        })

    }

    const handleCloseFilters = (e,reason) =>{
        if(!reason){
            // setDateFilter(initialFilters);
            setShowFilters(false);
        }
    }

    const applyFilter = () =>{
        setShowFilters(false);
        setLoadingBackDrop(true);
        let filter = getComplementParams();
        // let filter = `{"where": {"${dateParam}": {"between": ["${from}", "${to}"]}}}`;
        loadComplements({filter});
        setAppliedFilter(true);
    }

    const getComplementParams = () =>{
        if(dateFilter.from && dateFilter.to){
            let from = dateFormatter(dateFilter.from['$d'],'-') + ' 00:00:00';
            let to = dateFormatter(dateFilter.to['$d'],'-') + ' 23:59:59';
            const dateParam = dateType == 'FC' ? 'creationDate' : 'paymentDate';
            let filter = `{
                "order": "${dateParam} DESC",
                "where": {
                    "and":[{"userId": ${userId}},{"${dateParam}": {"between": ["${from}", "${to}"]}}]
                },
                "include": ["invoice"]
            }`;
            // let filter = `{"order": "${dateParam} DESC", "where": {"${dateParam}": {"between": ["${from}", "${to}"]}}}`;
            return filter;
            
        }
        return filter;
    }

    const showInvoiceComplementDetails = (event,cellValues) =>{
        setSelectedInvoice(cellValues.row);
        setLoadingBackDrop(true);
        const complementId = cellValues.row.id;
        InvoiceService.getComplementDetails(complementId,(response,error) =>{
            setLoadingBackDrop(false);
            if(response){
                setInvoiceComplementDetails(response);
            }
            setOpenDetails(true);
        })

    }

    return(
        <Fragment>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingBackDrop}
            >
            <CircularProgress color="inherit" />
            </Backdrop>
            <FloatAlert/>
            <MenuComponent permissionName={"Complementos de pago proveedor"}>
                <DataTable columns={columns} rows={invoiceComplements}
                    filterDate={filterOrders}
                    getClassName={getClassName}
                    // onRowSelectionModelChange={(newRowSelectionModel) => {
                    //     setRowSelectionModel(newRowSelectionModel);
                    //   }}
                    // rowSelectionModel={rowSelectionModel}
                    />
                <Box sx={{flexGrow: 1}}></Box>
                <Box sx={{ '& > :not(style)': { m: 1 } }}>
                    <Fab  color="primary" aria-label="add" onClick={() =>{openUploadInvoiceComplement()}}
                        sx={{position: 'fixed',bottom: 16, right: 16,}}>
                        <AddOutlined />
                    </Fab>
                </Box>
            </MenuComponent>
            <Dialog disableEscapeKeyDown={true} open={open} onClose={handleClose}>
                <DialogTitle>Subir Complemento de Pago</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <TextField fullWidth variant="standard" required label="XML" value={btnUploadXml} /*onChange={(e) =>{setValue({...value,subject:e.target.value})}}*//>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Button fullWidth variant="contained" component="label">
                                {/* {btnUploadXml} */}
                                Cargar XML
                                <input hidden accept="application/xml" multiple type="file" onChange={handleCaptureXml} />
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField fullWidth variant="standard" required label="PDF" value={btnUploadPdf} /*onChange={(e) =>{setValue({...value,comments:e.target.value})}}*//>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Button fullWidth variant="contained" component="label">
                                Cargar PDF
                                {/* {btnUploadPdf} */}
                                <input hidden accept="application/pdf" multiple type="file" onChange={handleCapturePdf} />
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                <Button disabled={loading} startIcon={<CloseOutlined />} onClick={handleClose} size="small" variant="outlined">Cancelar</Button>
                <LoadingButton
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<Save />}
                    variant="outlined"
                    loadingIndicator={<CircularProgress color="primary" size={16}/>}
                    size="small"
                    disabled={(!xmlFile || !pdfFile)}
                    onClick={() =>{uploadInvoiceComplement()}}
                >
                    Enviar
                </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog disableEscapeKeyDown={true} open={openErrors} onClose={handleCloseErrors}>
                <DialogTitle>Complemento de pago con errores</DialogTitle>
                <DialogContent>
                    {
                        errors.map((error,idx) =>{
                            return(
                                <Fragment>
                                    {/* <Chip key={idx} label={error} color="warning" />
                                    <br/>
                                    <br/> */}
                                    <Typography variant="body2" color={"red"}>* {error}</Typography>
                                    <Divider/>
                                    {/* <br></br>
                                    <br></br> */}
                                </Fragment>
                                
                            )
                        })
                    }
                </DialogContent>
                <DialogActions>
                    <Button startIcon={<CloseOutlined />} onClick={handleCloseErrors} size="small" variant="outlined">Cerrar</Button>
                </DialogActions>
            </Dialog>
            <Dialog disableEscapeKeyDown={true} open={filters} onClose={handleCloseFilters} fullWidth>
                <DialogTitle>Filtrar Fecha</DialogTitle>
                <DialogContent>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        '& > :not(style)': { m: 1 }
                    }}>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1},
                            }}
                            noValidate
                            autoComplete="off">

                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Filtrar Fecha</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={dateType}
                                    onChange={(e) =>{setDateType(e.target.value)}}
                                >
                                    <FormControlLabel value="FP" control={<Radio />} label="F. Pago" />
                                    <FormControlLabel value="FC" control={<Radio />} label="F. Creación" />
                                </RadioGroup>
                            </FormControl>
                            <Box sx={{flexGrow: 1}}></Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="F. Inicio"
                                    openTo="day"
                                    views={['year', 'month', 'day']}
                                    value={dateFilter.from}
                                    onChange={(newValue) => {
                                        setDateFilter({...dateFilter,from: newValue});
                                    }}
                                    inputFormat="DD-MM-YYYY"
                                    renderInput={(params) => <TextField required size="small" {...params} />}
                                />
                                <Box sx={{flexGrow: 1}}></Box>
                            </LocalizationProvider>
                            <Box sx={{flexGrow: 1}}></Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="F. Fin"
                                    openTo="day"
                                    views={['year', 'month', 'day']}
                                    minDate={dateFilter.from}
                                    disabled={!dateFilter.from}
                                    value={dateFilter.to}
                                    onChange={(newValue) => {
                                        setDateFilter({...dateFilter,to: newValue});
                                    }}
                                    inputFormat="DD-MM-YYYY"
                                    renderInput={(params) => <TextField required size="small" {...params} />}
                                />
                            </LocalizationProvider>
                            
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                <Button startIcon={<CloseOutlined />} onClick={handleCloseFilters} size="small" variant="outlined">Cerrar</Button>
                <Button startIcon={<FilterListOutlined />} onClick={applyFilter} disabled={!(dateFilter.from && dateFilter.to)} size="small" variant="outlined">Filtrar</Button>
                </DialogActions>
            </Dialog>

            <Dialog disableEscapeKeyDown={true} open={openDetails} onClose={() =>{setOpenDetails(!openDetails)}} fullWidth maxWidth={'xl'}>
                <DialogTitle>Pagos complemento {selectedComplement.invoice?.serie}{selectedComplement.invoice?.folio}  ({selectedComplement.invoice?.fiscalFolio})</DialogTitle>
                <DialogContent>
                    <DataTable columns={columnsDetails} rows={invoiceComplementDetails}/>
                </DialogContent>
                <DialogActions>
                <Button startIcon={<CloseOutlined />} onClick={() =>{setOpenDetails(!openDetails)}} size="small" variant="outlined">Cerrar</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )

}

export default InvoiceComplements;