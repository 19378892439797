import React, { Fragment, useEffect, useState } from "react";
import MenuComponent from "../../Components/Menu/Menu";
import DataTable from "../../Components/DataTable/DataTable";
import { Backdrop, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fab, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Radio, RadioGroup, TextField, Tooltip, Typography } from "@mui/material";
import { AddOutlined, CloseOutlined, FilterListOutlined, Save, Visibility } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import FloatAlert from "../../Components/Alert";
import { OrderService } from "../../Services/OrderService";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dateFormatter from "../../Utils/DateFormatter";
import { InvoiceService } from "../../Services/InvoiceService";

const Payments = () =>{

    const [loadingBackDrop,setLoadingBackDrop] = useState(false);
    const [loading,setLoading] = useState(false);
    const [btnUploadXml,setBtnUploadXml] = useState("");
    const [btnUploadPdf,setBtnUploadPdf] = useState("");
    const [xmlFile,setXmlFile] = useState(null);
    const [pdfFile,setPdfFile] = useState(null);
    const [open,setOpen] = useState(false);
    const [openErrors,setOpenErrors] = useState(false);
    const [rowSelectionModel,setRowSelectionModel] = useState([]);
    const[payments,setPayments] = useState([]);
    const[errors,setErrors] = useState([]);
    const [filters, setShowFilters] = useState(false);
    const dispatch = useDispatch();
    const username = window.sessionStorage.getItem('username');
    const userId = window.sessionStorage.getItem('userId');
    const [filterType,setFilterType] = useState("X");
    const [appliedFilter, setAppliedFilter] = useState(false);
    let initialFilters = {
        from: '',
        to: '',
    }

    const {orderColumns} = useSelector(store => store.dataTableReducer);

    const[dateFilter,setDateFilter] = useState(initialFilters);

    let initialParams = {
        providerId: username,
        fromDate: '',
        toDate: ''
    }

    useEffect(() =>{
        setShowFilters(true);
        //loadPayments(initialParams);
    },[])


    const loadPayments = (params) =>{
        setLoadingBackDrop(true);
        InvoiceService.getPayments(params,(response,error) => {
            setLoadingBackDrop(false);
            if(response && response.success){
                setPayments(response.result);
            }
        })
    }

    function filterPayments (){
        return(
            <Fragment>
                <Button onClick={() =>{
                    setShowFilters(true);
                }} startIcon={<FilterListOutlined />} >FILTROS</Button>
                {
                    appliedFilter ?
                    <Tooltip title="Limpiar Filtro">
                        <IconButton onClick={() =>{
                            setDateFilter(initialFilters);
                            //setLoadingBackDrop(true);
                            setAppliedFilter(false);
                            //loadPayments(initialParams);
                            setPayments([]);
                        }} aria-label="clear" size="small" color="primary">
                            <CloseOutlined />
                        </IconButton>
                    </Tooltip>
                    : ''
                }
                

            </Fragment>
        )
    }

    const columns = [
        // { field: 'id', headerName: 'ID', width: 100},
        { field: 'providerId', headerName: 'Proveedor', width: 150},
        { field: 'paymentId', headerName: 'Pago', width: 150},
        { field: 'paymentDate', headerName: 'F. Pago', width: 150},
        { field: 'currency', headerName: 'Moneda', width: 150},
        { field: 'amount', headerName: 'Monto', width: 150},
        { field: 'uuid', headerName: 'UUID', width: 400},
    ];

    const handleCaptureXml = ({ target }) => {
        setBtnUploadXml(target.files[0].name)
        setXmlFile(target.files[0]);
    };

    const handleCapturePdf = ({ target }) => {
        setBtnUploadPdf(target.files[0].name)
        setPdfFile(target.files[0]);
    };

    const handleClose = (e,reason) =>{
        if(!reason){
            setOpen(false);
            setBtnUploadXml("");
            setBtnUploadPdf("");
            setXmlFile(null);
            setPdfFile(null);
            setRowSelectionModel([]);
        }

    }
    const handleCloseErrors = (e,reason) =>{
        if(!reason){
            setOpenErrors(false);
            setErrors([]);
        }

    }

    const openUploadInvoice = () =>{
        if(rowSelectionModel.length){
            setOpen(true);
        }
        else{
            let props = {open: true,
                severity: 'warning',
                message: `Es necesario seleccionar al menos un registro`};
            dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
        }
    }

    const uploadInvoiceComplement = () =>{
        setLoading(true);
        let selectedPayments = [];
        for (const id of rowSelectionModel) {
            let order = payments.find((o) => o.id == id);
            if(order)
                selectedPayments.push(order);
        }
        const formData = new FormData();
        formData.append("file", xmlFile);    
        formData.append("file", pdfFile);    
        formData.append("payments",JSON.stringify(selectedPayments));
        formData.append("userId",userId);
        InvoiceService.uploadInvoiceComplement(formData,username,(response,error) =>{
            setLoading(false);
            if(response && !error){
                if(response.success){
                    let props = {open: true,
                        severity: 'success',
                        message: `Complemento cargado correctamente`};
                    dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
                    handleClose();
                    loadPayments(getPaymentParams());

                }
                else{
                    handleClose();
                    setErrors(response.errors);
                    setOpenErrors(true);
                }
            }
            else{
                let props = {open: true,
                    severity: 'error',
                    message: error.message}
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
            }
        })

    }

    const handleCloseFilters = (e,reason) =>{
        if(!reason){
            // setDateFilter(initialFilters);
            setShowFilters(false);
        }
    }

    const applyFilter = () =>{
        setShowFilters(false);
        setAppliedFilter(true);
        
        loadPayments(getPaymentParams());
    }

    const getPaymentParams = () =>{
        let from = dateFilter.from == '' ? "" : dateFormatter(dateFilter.from['$d'],'');
        let to = dateFilter.to == '' ? "" : dateFormatter(dateFilter.to['$d'],'');
        const params = {
            providerId: '',
            fromDate: from,
            toDate: to
        }
        return params;
    }

    return(
        <Fragment>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingBackDrop}
            >
            <CircularProgress color="inherit" />
            </Backdrop>
            <FloatAlert/>
            <MenuComponent permissionName={"Pagos (Admin)"}>
                <DataTable columns={columns} rows={payments} 
                    checkboxSelection={true} disableRowSelectionOnClick={true} 
                    setSelectionModel={setRowSelectionModel}
                    selectionModel={rowSelectionModel}
                    filterDate={filterPayments}
                    // columnVisibilityModel={orderColumns}
                    // setColumnVisibilityModel={(newModel) =>{dispatch({type:'UPDATE_ORDER_COLUMS',idx:'orderColumns',value: newModel})}}
                    />
            </MenuComponent>
            
            <Dialog disableEscapeKeyDown={true} open={filters} onClose={handleCloseFilters} fullWidth>
                <DialogTitle>Filtrar pagos</DialogTitle>
                <DialogContent>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        '& > :not(style)': { m: 1 }
                    }}>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1},
                            }}
                            noValidate
                            autoComplete="off">

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="F. Inicio"
                                    openTo="day"
                                    views={['year', 'month', 'day']}
                                    value={dateFilter.from}
                                    onChange={(newValue) => {
                                        setDateFilter({...dateFilter,from: newValue});
                                    }}
                                    inputFormat="DD-MM-YYYY"
                                    renderInput={(params) => <TextField required size="small" {...params} />}
                                />
                                <Box sx={{flexGrow: 1}}></Box>
                            </LocalizationProvider>
                            <Box sx={{flexGrow: 1}}></Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="F. Fin"
                                    openTo="day"
                                    views={['year', 'month', 'day']}
                                    minDate={dateFilter.from}
                                    disabled={!dateFilter.from}
                                    value={dateFilter.to}
                                    onChange={(newValue) => {
                                        setDateFilter({...dateFilter,to: newValue});
                                    }}
                                    inputFormat="DD-MM-YYYY"
                                    renderInput={(params) => <TextField required size="small" {...params} />}
                                />
                            </LocalizationProvider>
                        </Box>
                        
                    </Box>
                </DialogContent>
                <DialogActions>
                <Button startIcon={<CloseOutlined />} onClick={handleCloseFilters} size="small" variant="outlined">Cerrar</Button>
                <Button startIcon={<FilterListOutlined />} onClick={applyFilter} size="small" variant="outlined">Filtrar</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )

}

export default Payments;