const dateTimeFormatter = (dateTime,join) => {
    if(dateTime){
        const date = new Date(dateTime);
        var mm = date.getUTCMonth() + 1;
        var dd = date.getUTCDate();
        var hours = date.getUTCHours();
        var minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12 || 12;
        return [
                (dd>9 ? '' : '0') + dd,
                (mm>9 ? '' : '0') + mm,
                date.getUTCFullYear()
               ].join(join) + ' ' + hours + ':' + minutes + ' ' + ampm;
    }
    return '';
};

export default dateTimeFormatter;