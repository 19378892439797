import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import DomainAddOutlinedIcon from '@mui/icons-material/DomainAddOutlined';
import CarRepairOutlinedIcon from '@mui/icons-material/CarRepairOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import { AttachMoneyOutlined, CardMembershipOutlined, HelpOutlineOutlined, PaymentsOutlined, PersonAddOutlined, PersonOutlineOutlined, Receipt, ReceiptOutlined, RuleOutlined, Settings } from '@mui/icons-material';

export const MenuItems = [
    {
        id: 0,
        name:"Inicio",
        url: "/portal/home",
        icon: <HomeOutlinedIcon/>,
    },
    {
        id: 2,
        name:"Proveedores",
        url: "/portal/providers",
        icon: <PersonOutlineOutlined/>,
        //operationId: 1,
        permissionName: 'Proveedores',
    },
    {
        id: 3,
        name:"Invitación Proveedores",
        url: "/portal/invitation/provider",
        icon: <CardMembershipOutlined/>,
        //operationId: 1,
        permissionName: 'Invitaciones Proveedores',
    },
    {
        id: 4,
        name:"Pedidos pendientes",
        url: "/portal/providers/pendingOrders",
        icon: <ListAltOutlinedIcon/>,
        //operationId: 1,
        permissionName: 'Pedidos pendientes',
    },
    {
        id: 1,
        name:"Centro Ayuda",
        url: "/portal/helpCenter",
        icon: <HelpOutlineOutlined/>,
        //operationId: 1,
        permissionName: 'Centro de ayuda',
    },
    {
        id: 5,
        name:"Roles-Permisos",
        url: "/portal/permissions",
        icon: <RuleOutlined/>,
        //operationId: 1,
        permissionName: 'Permisos',
    },
    {
        id: 6,
        name:"Usuarios",
        url: "/portal/users",
        icon: <PersonAddOutlined/>,
        //operationId: 1,
        permissionName: 'Usuarios',
    },
    {
        id: 7,
        name:"Centro Ayuda (Admin)",
        url: "/portal/helpCenterAdmin",
        icon: <HelpOutlineOutlined/>,
        //operationId: 1,
        permissionName: 'Centro ayuda (Administrador)',
    },
    {
        id: 8,
        name:"Mis Facturas",
        url: "/portal/providers/invoices",
        icon: <AttachMoneyOutlined/>,
        //operationId: 1,
        permissionName: 'Facturas Proveedor',
    },
    {
        id: 9,
        name:"Facturas Proveedores",
        url: "/portal/invoices",
        icon: <AttachMoneyOutlined/>,
        //operationId: 1,
        permissionName: 'Facturas',
    },
    {
        id: 10,
        name:"Config. de Parámetros",
        url: "/portal/parameter-config",
        icon: <Settings />,
        //,
        permissionName: 'Configuración de Parámetros'
    },
    {
        id: 11,
        name:"Ordenes de compra",
        url: "/portal/orders",
        icon: <ListAltOutlinedIcon/>,
        //operationId: 1,
        permissionName: 'Ordenes de compra',
    },
    {
        id: 12,
        name:"Mis Complementos",
        url: "/portal/provider/invoiceComplements",
        icon: <ReceiptOutlined/>,
        //operationId: 1,
        permissionName: 'Complementos de pago proveedor',
    },
    {
        id: 13,
        name:"Complementos de Pago",
        url: "/portal/invoiceComplements",
        icon: <ReceiptOutlined/>,
        //operationId: 1,
        permissionName: 'Complementos de pago (Admin)',
    },
    {
        id: 14,
        name:"Mis Pagos",
        url: "/portal/provider/payments",
        icon: <PaymentsOutlined/>,
        //operationId: 1,
        permissionName: 'Pagos Proveedor',
    },
    {
        id: 15,
        name:"Pagos",
        url: "/portal/payments",
        icon: <PaymentsOutlined/>,
        //operationId: 1,
        permissionName: 'Pagos (Admin)',
    },
    // {
    //     id: 1,
    //     name:"Empresas",
    //     url: "/admin/catalogs/enterprises",
    //     icon: <DomainAddOutlinedIcon/>,
    //     operationId: 1,
    //     permissionName: 'Empresas',
    // },
    // {
    //     id: 2,
    //     name:"Departamentos",
    //     url: "/admin/catalogs/departments",
    //     icon: <AssignmentIndOutlinedIcon/>,
    //     operationId: 2,
    //     permissionName: 'Departamentos',
    // },
    // {
    //     id: 3,
    //     name:"Menú de validaciones",
    //     url: "/admin/catalogs/menuValidations",
    //     icon: <AssignmentIcon/>,
    //     operationId: 3,
    //     permissionName: 'Menú de validaciones',
    // },
    // {
    //     id: 4,
    //     name:"Partes de unidades",
    //     url: "/admin/catalogs/shiftingUnitParts",
    //     icon: <CarRepairOutlinedIcon/>,
    //     operationId: 6,
    //     permissionName: 'Partes de unidades',
    // },
    // {
    //     id: 5,
    //     name:"Tipo de Unidades",
    //     url: "/admin/catalogs/shiftingUnitTypes",
    //     icon: <ListAltOutlinedIcon/>,
    //     operationId: 4,
    //     permissionName: 'Tipo de unidades',
    // },
    // {
    //     id: 8,
    //     name:"Unidades",
    //     url: "/admin/catalogs/shiftingUnits",
    //     icon: <LocalShippingIcon/>,
    //     operationId: 5,
    //     permissionName:"Unidades",
    // },
    // {
    //     id: 9,
    //     name:"Operadores",
    //     url: "/admin/catalogs/drivers",
    //     icon: <AirlineSeatReclineNormalIcon/>,
    //     operationId: 9,
    //     permissionName:"Operadores",
    // },
    // {
    //     id: 10,
    //     name:"Auditorias",
    //     url: "/admin/audits",
    //     icon: <FactCheckOutlinedIcon/>,
    //     operationId: 10,
    //     permissionName:"Auditorias",
    // },
    // {
    //     id: 6,
    //     name:"Usuarios",
    //     url: "/admin/users",
    //     icon: <GroupAddOutlinedIcon/>,
    //     operationId: 7,
    //     permissionName:"Usuarios",
    // },
    // {
    //     id: 7,
    //     name:"Permisos",
    //     url: "/admin/users/permissions",
    //     icon: <RuleOutlined/>,
    //     operationId: 8,
    //     permissionName:"Permisos",
    // }
    

]