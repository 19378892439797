import API_ROUTES from "../Routes/APIRoutes";

export const ParameterConfigService = {
  createParameterConfig: (data, callback) => {
    fetch(API_ROUTES.createParameterConfig, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) callback(null, response.error);
        else callback(response);
      })
      .catch((err) => callback(null, err));
  },

  countParameterConfigs: (callback) => {
    fetch(API_ROUTES.countParameterConfig, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) callback(null, response.error);
        else callback(response);
      })
      .catch((err) => callback(null, err));
  },

  findParameterConfigs: (callback) => {
    fetch(API_ROUTES.findParameterConfigs, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) callback(null, response.error);
        else callback(response);
      })
      .catch((err) => callback(null, err));
  },

  findParameterConfigsByUserId: (userId, callback) => {
    fetch(API_ROUTES.findParameterConfigsByUserId(userId), {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        if (response.error) callback(null, response.error);
        else callback(response);
      })
      .catch((err) => callback(null, err));
  },

  getInfoParameterConfigById: (id, callback) => {
    fetch(`${API_ROUTES.getInfoParameterConfigById(id)}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
      },
    })
    .then((res) => res.json())
    .then((response) => {
      if (response.error) callback(null, response.error);
      else callback(response);
    })
    .catch((err) => callback(null, err));
  },
  

  getStaticParameterConfigs: (callback) => {
    fetch(API_ROUTES.getStaticParameterConfigs, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) callback(null, response.error);
        else callback(response);
      })
      .catch((err) => callback(null, err));
  },

  patchParameterConfigById: (id, data, callback) => {
    const filteredData = {};
    if (data.status !== undefined) filteredData.status = data.status;
    if (data.value !== undefined) filteredData.value = data.value;

    fetch(API_ROUTES.patchParameterConfigById(id), {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
      },
      body: JSON.stringify(filteredData),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) callback(null, response.error);
        else callback(response);
      })
      .catch((err) => callback(null, err));
  },

  putParameterConfigById: (id, data, callback) => {
    fetch(API_ROUTES.putParameterConfigById(id), {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
      },
      body: JSON.stringify(data),
    })
    .then((res) => res.json())
    .then((response) => {
      if (response.error) callback(null, response.error);
      else callback(response);
    })
    .catch((err) => callback(null, err));
  },

  deleteParameterConfigById: (id, callback) => {
    fetch(API_ROUTES.deleteParameterConfigById(id), {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
      },
    })
    .then((res) => res.json())
    .then((response) => {
      if (response.error) callback(null, response.error);
      else callback(response);
    })
      .catch((err) => callback(null, err));
  },  
};
