import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { Fragment, useEffect, useState } from "react";
// import { UserServices } from "../../../Services/UserService";
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import { green } from "@mui/material/colors";
import { useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Save } from "@mui/icons-material";
import MenuComponent from "../../Components/Menu/Menu";
import FloatAlert from "../../Components/Alert";
import DataTable from "../../Components/DataTable/DataTable";
import { UserService } from "../../Services/UserService";

const RolesPermissions = () =>{

    let dispatch = useDispatch();
    let [roles, setRoles] = useState([]);
    let [permissions, setPermissions] = useState([]);
    let [open, setOpen] = useState(false);
    let [loading, setLoading] = useState(false);
    let [pemissionSelected, setPermissionsSelected] = useState([]);
    let [rolSelected,setRolSelected] = useState(0);
    let [loadingBackDrop, setLoadingBackDrop] = useState(false);
    

    useEffect(() =>{
        setLoadingBackDrop(true);
        getRoles();
        getPermissions();

    },[]);

    let getRoles = () =>{
        let params = {
            filter: `{"where": {"status" : 1}}`
        }
        UserService.getRoles(params,(response,error) =>{
            setLoadingBackDrop(false);
            if(response && response.length){
                setRoles(response);
            }
        });
    }

    let getPermissions = () =>{
        let filter = '{"where":{"status":1}}';
        UserService.getApplicationOperations({filter: filter}, (response,error) =>{
            if(response && response.length)
                setPermissions(response);
        })
    }

    let showPermissions = (e,cellValues) =>{
        setLoadingBackDrop(true);
        setRolSelected(cellValues.row.id);
        let rolId = cellValues.row.id;
        let params = {
            filter: `{"where":{"roleId":${rolId}}, "include":["applicationOperation"]}`
        }
        UserService.getApplicationAuthorizations(params,(response,error) =>{
            setLoadingBackDrop(false);
            if(error){
                let props = {open: true,
                    severity: 'error',
                    message: error.message}
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
            }
            else if(response){
                let permissions = [];
                for (const key in response) {
                    permissions.push(response[key].applicationOperationId);
                }
                pemissionSelected = permissions;
                setPermissionsSelected(permissions);
                setOpen(true);
            }
        })
        
        

    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Rol', width: 200},
        { field: 'prefix', headerName: 'Prefijo', width: 200},
        // { field: 'secondLastName', headerName: 'Apellido materno', width: 200,
        // valueGetter: (params) =>{
        //     return getName(params,"secondLastName")
        // },
        // },
        // { field: 'email', headerName: 'Usuario', width: 200 },
        {
            field: '',
            headerName: 'Opciones',
            description: 'This column has a value getter and is not sortable.',
            width: 160,
            renderCell: (cellValues) => {
                return (
                    <React.Fragment>
                         <Tooltip title="Ver permisos">
                            <IconButton aria-label="edit" 
                                onClick={(event) => {
                                    showPermissions(event,cellValues);
                                }}
                                >
                                    <VisibilityIcon />
                            </IconButton>
                        </Tooltip>
                        
                        
                        
                    </React.Fragment>
                );
            }
            
        },
    ];

    const handleClose = (e,reason) => {
        if(!reason){
            setOpen(false);
        }
    };


    const addPermissions = () => {
        setLoading(true);
        let params = {
            roleId: rolSelected,
            operationsId: pemissionSelected
            
        };
        UserService.addApplicationAuthorizationToRole(params,(response,error) =>{
            setLoading(false);
            if(error){
                let props = {open: true,
                    severity: 'error',
                    message: error.message}
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
            }
            else if (response && response.success){
                let props = {open: true,
                    severity: 'success',
                    message: response.msg}
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
                setOpen(false);
                setPermissionsSelected([]);
            }
        });

    };

    const permissionColums = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'name', headerName: 'Permiso', width: 300},
    ];

    return(
        <Fragment>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingBackDrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <FloatAlert/>
            <MenuComponent permissionName={"Permisos"}>
                <DataTable
                    rows={roles}
                    columns={columns}
                />
            </MenuComponent>
            
            <Dialog maxWidth="sm"
                fullWidth style={{ zIndex: 0 }} 
            disableEscapeKeyDown={true} open={open} onClose={handleClose}>
                <DialogTitle>Permisos</DialogTitle>
                <DialogContent>
                    <DataGrid
                        rows={permissions}
                        columns={permissionColums}
                        pageSize={50}
                        rowsPerPageOptions={[50]}
                        style={{height:300}}
                        checkboxSelection
                        onSelectionModelChange={(ids) => {
                            setPermissionsSelected(ids);
                        }}
                        selectionModel={pemissionSelected}
                    />
                </DialogContent>
                <DialogActions>
                <Button disabled={loading} startIcon={<CloseOutlinedIcon />} onClick={handleClose} size="small" variant="outlined">Cancelar</Button>
                <LoadingButton
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<Save />}
                    variant="outlined"
                    loadingIndicator={<CircularProgress color="primary" size={16}/>}
                    size="small"
                    // disabled={validateInputs()}
                    onClick={addPermissions}
                >
                    Guardar
                </LoadingButton>
            </DialogActions>
            </Dialog>
        </Fragment>
        
    )
}

export default RolesPermissions;