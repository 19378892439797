import React, { Fragment, useEffect, useState, useRef } from "react";
import Menu from "../../Components/Menu/Menu";
import { Backdrop, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, IconButton, Paper, TextField, Tooltip, styled, Typography, Badge } from "@mui/material";
import FloatAlert from "../../Components/Alert";
import DataTable from "../../Components/DataTable/DataTable";
import { AddOutlined, AttachFileOutlined, CloseOutlined, DownloadOutlined, Save, Visibility, Chat, Send, Refresh} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { OperationServices } from "../../Services/OperationService";
import dateTimeFormatter from "../../Utils/DateTimeFormatter";

const HelpCenterIndex = () =>{

    const dispatch = useDispatch();
    let userId = Number(window.sessionStorage.getItem("userId"));

    const initialValue = {
        subject: "",
        userId: userId,
        comments: ""
    }

    const initialChat = {
        ticketId: null,
        userSentId: userId,
        userSentRole: "client",
        numMessagesUnread: 0,
    }

    const [loading,setLoading] = useState(false);
    const [loadingBackDrop,setLoadingBackDrop] = useState(false);
    const [open,setOpen] = useState(false);
    const [openDetails,setOpenDetails] = useState(false);
    const [value,setValue] = useState(initialValue);
    const [btnUploadText, setBtnUploadText] = useState("Cargar archivos");
    const [files,setFiles] = useState(null);
    const [chipData, setChipData] = React.useState([]);
    const [openChat, setOpenChat] = useState(false);
    const [refreshChat, setRefreshChat] = useState(false);
    const [valueChat, setValueChat] = useState(initialChat);
    const [messages, setMessages] = useState([]);
    const [tickets, setTickets] = useState([]);
    const inputRef = useRef(null);
    const messagesEndRef = useRef(null);

    useEffect(() =>{
        getHelpCenterTickets();
    },[])

    useEffect(() => {
        if (refreshChat) {
            changeStatusMessage();
            scrollToBottom();
            setRefreshChat(false);
        }
    }, [refreshChat]);

    useEffect(() => {
        if (openChat) {
            changeStatusMessage();
            scrollToBottom();
        }
    }, [openChat, messages]);

    const getHelpCenterTickets = () =>{
        setLoadingBackDrop(true);
        let params = {
            filter: `{"order": "creationDate DESC","where": {"userId" : ${userId}}}`
        }
        OperationServices.getHelpCenterTickets(params, (response,error) =>{
            setLoadingBackDrop(false);
            if(response?.length){
                setTickets(response);
            }
        })
    }

    const showDetails = (e,cellValues) => {
        setValue({...value,id:cellValues.row.id, subject:cellValues.row.subject,comments: cellValues.row.comments,status: cellValues.row.status,answer: cellValues.row.answer});
        let chipArray = [];
        for (const file of cellValues.row.evidencePath) {
            let filePath = file.split('/');
            if(filePath.length > 1){
                let data = {
                    label: filePath[(filePath.length - 1)]
                }
                chipArray.push(data);
            }
            
        }
        //setBtnUploadText(target.files[0].name)
        
        
        setChipData(chipArray);
        setOpenDetails(true);
        //setSolvedEvidence({...solvedEvidence,image: target.files[0]});
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'subjetc', headerName: 'Asunto', width: 200,
            valueGetter: (params) =>{
                return params.row.subject;
            },
        },
        { field: 'Fecha', headerName: 'Fecha', width: 170,
            valueGetter: (params) =>{
                return params.row.creationDate.substring(0,19).replace('T',' ');
                // return moment.tz(params.row.creationDate,'America/Mexico_City').format("YYYY-MM-DD HH:mm");
            },
        },
        { field: 'status', headerName: 'Estatus', width: 170,
            valueGetter: (params) =>{
                let status = "";
                switch(params.row.status){
                    case 1:
                        status = "ENVIADO"
                        break;
                    case 2:
                        status = "EN REVISIÓN"
                        break;
                    case 4:
                        status = "SOLUCIONADO"
                        break;
                    case 0:
                        status = "CERRADO"
                        break;
                }
                return status
            },
        },
        { field: 'solvedDate', headerName: 'F. Solución', width: 200,
            valueGetter: (params) =>{
                return params.row.solvedDate?.replace('T', ' ').substring(0,16);
            },
        },
        {
            field: '',
            headerName: 'Opciones',
            description: 'This column has a value getter and is not sortable.',
            width: 160,
            renderCell: (cellValues) => {
                const messagesToClient = cellValues.row.numMessagesUnreadClient;
                return (
                    <React.Fragment>
                         <Tooltip title="Ver detalles">
                            <IconButton aria-label="edit" 
                                onClick={(event) => {
                                    showDetails(event,cellValues);
                                }}
                                >
                                    <Visibility />
                            </IconButton>
                        </Tooltip>
                        {/* <Tooltip title="Chat">
                            <IconButton aria-label="chat" onClick={() => {handleOpenChat(cellValues);}}>
                                <Badge color="primary" badgeContent={messagesToClient} max={999} >
                                    <Chat />
                                </Badge>
                            </IconButton>
                        </Tooltip> */}
                    </React.Fragment>
                );
            }
            
        },
    ];

    const getClassName = (params) => {
        if (params.field === "status") {
          return params.value === "ENVIADO" ? "cell-yellow" : params.value == 'EN REVISIÓN' ? "cell-orange" : "cell-green";
        }
        return "";
    }

    const addTicket = () =>{
        setLoading(true);
        const formData = new FormData();
        if(files){
            files.forEach(file => {
                formData.append("file", file);    
            });
        }
        formData.append("subject", value.subject);
        formData.append("comments", value.comments);
        formData.append("userId", Number(value.userId));
        OperationServices.addHelpCenterTicket(formData,(response,error) =>{
            setLoading(false);
            if(response && !error){
                let props = {open: true,
                    severity: 'success',
                    message: `Registro agregado correctamente.`};
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
                handleClose();
                getHelpCenterTickets();
            }
            else{
                let props = {open: true,
                    severity: 'error',
                    message: error.message}
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
            }

        });

    };

    const handleClose = (e,reason) =>{
        if(!reason){
            setOpen(false);
            setValue(initialValue);
            setFiles(null);
            //setBtnUploadText("Cargar archivos")
            setChipData([]);
        }
    }

    const handleCloseDetails = (e,reason) =>{
        if(!reason){
            setOpenDetails(false);
            setValue(initialValue);
            setChipData([]);
        }
    }

    const handleClickOpen = () =>{
        setOpen(true);
    }

    const handleCapture = ({ target }) => {
        //setBtnUploadText(target.files[0].name)
        let arrayFile = [];
        let chipArray = [];
        for (let index = 0; index < target.files.length; index++) {
            const element = target.files[index];
            let size = (element.size / 1000000)
            if(size <= 3){
                arrayFile.push(element);
                let fileName = element.name;
                if(fileName.length > 20){
                    fileName = fileName.substring(0,20) + '...';
                }
                let fileObj = {
                    key: index,
                    label: fileName
                }
                chipArray.push(fileObj);
            }
            else{
                chipArray = [];
                arrayFile = [];
                let props = {open: true,
                    severity: 'error',
                    message: `El archivo ${element.name} excede los 3 MB.`}
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
                break;
            }
        }
        setChipData(chipArray);
        setFiles(arrayFile);
        //setSolvedEvidence({...solvedEvidence,image: target.files[0]});
    };

    const ListItem = styled('li')(({ theme }) => ({
        margin: theme.spacing(0.5),
    }));

    const handleDelete = (chipToDelete) => () => {
        setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    };

    const handleOpenChat = (ticket) => {
        setValueChat({...initialChat, ticketId: ticket.row.id, numMessagesUnread: ticket.row.numMessagesUnreadClient});
        setOpenChat(true);
        getMessages(ticket.row.id);
    };

    const handleCloseChat = () => {
        setOpenChat(false);
        setRefreshChat(false);
        setValueChat(initialChat);
        setMessages([]);
        inputRef.current.value = '';
        getHelpCenterTickets();
    };

    const getMessages = async (ticketId) => {
        let params = {
            filter: `{ "include":[{"relation":"userSent"}], "order": "creationDate ASC", "where": {"ticketId" : ${ticketId}}}`
        }
        OperationServices.getMessagesHelpCenterTicket(params, (response, error) =>{
            if(!error){
                if (response?.length) {
                    setMessages(response);
                }else{
                    setMessages([]);
                }
            }
            else{
                let props = {
                    open: true,
                    severity: 'error',
                    message: error.message
                }
                dispatch({type:'UPDATE_ALERT_VALUES', idx:'alert', value:{props}});
            }
        })
    };

    const handleSendMessage = async () => {
        if (!inputRef.current.value.trim()) {
            let props = {open: true,
                severity: 'error',
                message: 'El mensaje no puede estar vacío.'}
            dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
            return;
        }
        const newMessageData = {
            role: valueChat.userSentRole,
            body: {
                ticketId: Number(valueChat.ticketId),
                content: inputRef.current.value,
                userSentId: Number(valueChat.userSentId),
            }
        }
        OperationServices.sendMessageHelpCenterTicket(newMessageData, (response, error) =>{
            if(response?.success && !error){
                inputRef.current.value = '';
                inputRef.current.style.height = 'auto';
                setMessages([...messages, response.result]);
            }else{
                let props = {open: true,
                    severity: 'error',
                    message: error?.message || response?.msg}
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
            }
        });
    };

    const changeStatusMessage = () => {
        const updatedMessage = {
            role: valueChat.userSentRole,
            ticketId: value.ticketId,
            body: {
                messagesIds: []
            }
        };
        messages.filter(message => !message.isRead && message.userSentId !== userId).forEach(message => {
            updatedMessage.ticketId = message.ticketId;
            updatedMessage.body.messagesIds.push(message.id)
        });
        if (updatedMessage.body.messagesIds.length) {
            OperationServices.changeStatusMessage(updatedMessage, (response, error) => {
                if (!response?.success && error) {
                    let props = {
                        open: true,
                        severity: 'error',
                        message: error.message
                    };
                    dispatch({ type: 'UPDATE_ALERT_VALUES', idx: 'alert', value: { props } });
                }
            });
        }
    };

    const handleInputRow = (e) => {
        const input = inputRef.current;
        if (input) {
            input.style.height = 'auto';
            const newHeight = Math.min(69, input.scrollHeight);
            input.style.height = `${newHeight}px`;
        }
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const handleRefreshChat = () => {
        setRefreshChat(true);
        getMessages(valueChat.ticketId);
    }

    return(
        <Fragment>
            <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loadingBackDrop}
                >
                    <CircularProgress color="inherit" />
            </Backdrop>
            <FloatAlert/>
            <Menu permissionName={"Centro de ayuda"}>
                <DataTable rows={tickets} columns={columns} getClassName={getClassName}/>
                <Box sx={{flexGrow: 1}}></Box>
                <Box sx={{ '& > :not(style)': { m: 1 } }}>
                    <Fab  color="primary" aria-label="add" onClick={() =>{handleClickOpen()}}
                        sx={{position: 'fixed',bottom: 16, right: 16,}}>
                            <Tooltip title="Nuevo ticket">
                                <AddOutlined />
                            </Tooltip>
                    </Fab>
                </Box>
                <Box sx={{ '& > :not(style)': { m: 1 } }}>
                        <Fab  color="primary" aria-label="add" onClick={() =>{getHelpCenterTickets()}}
                            sx={{position: 'fixed',bottom: 16, right: 80,}}>
                            <Tooltip title="Actualizar">
                                <Refresh />
                            </Tooltip>
                        </Fab>
                </Box>
            </Menu>

            <Dialog disableEscapeKeyDown={true} open={open} onClose={handleClose}>
                <DialogTitle>Nuevo Ticket</DialogTitle>
                <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <TextField fullWidth variant="standard" required label="Asunto" value={value.subject} onChange={(e) =>{setValue({...value,subject:e.target.value})}}/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField multiline rows={2} fullWidth variant="standard" required label="Comentarios" value={value.comments} onChange={(e) =>{setValue({...value,comments:e.target.value})}}/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Button variant="contained" component="label">
                            {btnUploadText}
                            <input hidden accept="image/*, application/pdf" multiple type="file" onChange={handleCapture} />
                        </Button>
                    </Grid>
                    {
                        chipData.length ? 
                        <Grid item xs={12} sm={12}>
                            <Paper
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    listStyle: 'none',
                                    p: 0.5,
                                    m: 0,
                                }}
                                component="ul"
                                >
                                {chipData.map((data) => {
                                    let icon;

                                    return (
                                    <ListItem key={data.key}>
                                        <Chip
                                            icon={icon}
                                            label={data.label}
                                            onDelete={handleDelete(data)}
                                        />
                                    </ListItem>
                                    );
                                })}
                            </Paper>
                        </Grid>
                        : ""
                    }
                </Grid>
                </DialogContent>
                <DialogActions>
                <Button disabled={loading} startIcon={<CloseOutlined />} onClick={handleClose} size="small" variant="outlined">Cancelar</Button>
                <LoadingButton
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<Save />}
                    variant="outlined"
                    loadingIndicator={<CircularProgress color="primary" size={16}/>}
                    size="small"
                    //disabled={!value.name.length}
                    onClick={addTicket}
                >
                    Guardar
                </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog disableEscapeKeyDown={true} open={openDetails} onClose={handleCloseDetails}>
                <DialogTitle>Ticket</DialogTitle>
                <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <TextField fullWidth variant="standard" inputProps={{readOnly: true}} required label="Asunto" value={value.subject} onChange={(e) =>{setValue({...value,subject:e.target.value})}}/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField multiline rows={2} fullWidth inputProps={{readOnly: true}} variant="standard" required label="Comentarios" value={value.comments} onChange={(e) =>{setValue({...value,comments:e.target.value})}}/>
                    </Grid>
                    {
                        chipData.length ? 
                        <Grid item xs={12} sm={12}>
                            <Paper
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    listStyle: 'none',
                                    p: 0.5,
                                    m: 0,
                                }}
                                component="ul"
                                >
                                {chipData.map((data,i) => {
                                    let icon;

                                    return (
                                    <ListItem key={i}>
                                        <Chip
                                            icon={<AttachFileOutlined/>}
                                            label={data.label}
                                            // onClick={() =>{downloadEvidence(data.label)}}
                                        />
                                    </ListItem>
                                    );
                                })}
                            </Paper>
                        </Grid>
                        : ""
                    }
                    <Grid item xs={12} sm={12}>
                        <TextField multiline rows={2} fullWidth variant="standard" inputProps={{readOnly: true}} required label="Solución" value={value.answer} onChange={(e) =>{setValue({...value,answer:e.target.value})}}/>
                    </Grid>
                </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} startIcon={<CloseOutlined />} onClick={handleCloseDetails} size="small" variant="outlined">Cerrar</Button>
                </DialogActions>
            </Dialog>
            <Dialog disableEscapeKeyDown={true} open={openChat} onClose={handleCloseChat} maxWidth="sm" fullWidth PaperProps={{sx: {maxWidth: '70%', width: '700px', margin: 'auto',}}}>
                <Grid container spacing={3}>
                    <Grid item sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems:"center"}}>
                        <DialogTitle>Chat de ayuda</DialogTitle>
                        <DialogActions>
                            <Button disabled={loading} startIcon={<CloseOutlined color="error"/>} color="error" onClick={handleCloseChat} size="small" variant="outlined">Cerrar</Button>
                            <LoadingButton
                                loading={loading}
                                loadingPosition="start"
                                startIcon={<Refresh />}
                                variant="outlined"
                                loadingIndicator={<CircularProgress color="primary" size={16}/>}
                                size="small"
                                onClick={() => {handleRefreshChat()}}
                            >
                                Refrescar
                            </LoadingButton>
                        </DialogActions>
                    </Grid>
                </Grid>
                <DialogContent sx={{
                    height: '400px',
                    maxHeight: '70%',
                    overflowY: 'scroll',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'normal',
                    borderRadius: '9px',
                    borderTop: '1px solid #ccc',
                    borderBottom: '1px solid #ccc',
                    bgcolor: '#ededed',
                }}>
                    {messages.map((msg, index) => (
                        <Box
                            key={index}
                            sx={{
                                maxWidth: '100%',
                                display: 'flex',
                                flexDirection: msg.userSentId === userId ? 'row-reverse' : 'row',
                                mb: 1,
                            }}
                        >
                            <Box
                                sx={{
                                maxWidth: '50%',
                                padding: '8px 12px',
                                borderTopLeftRadius:  msg.userSentId === userId ? '12px' : '0',
                                borderTopRightRadius: msg.userSentId === userId ? '0' : '12px',
                                borderBottomLeftRadius: '12px',
                                borderBottomRightRadius: '12px',
                                bgcolor: msg.userSentId === userId ? 'primary.light' : '#ffffff',
                                color: 'black',
                                textAlign: "start",
                                boxShadow: '2px 5px 4px 0.5px rgba(0,0,0,0.15)',
                                }}
                            >
                                <Tooltip title={msg.userSentId === userId ? "Tú " + dateTimeFormatter(msg.creationDate, '/'): msg.userSent.email.split('@')[0] + " " + dateTimeFormatter(msg.creationDate, '/')}>
                                    <Typography variant="body2" sx={{
                                        whiteSpace: 'pre-wrap',
                                        wordWrap: 'break-word',
                                        font: "550 1.03em 'Roboto', sans-serif",
                                        }}>
                                        {msg.content}
                                    </Typography>
                                </Tooltip>
                            </Box>
                        </Box>
                    ))}
                    <div ref={messagesEndRef} />
                </DialogContent>
                <DialogContent >
                    <Grid container spacing={2} sx={{ padding: '0', }}>
                        <Grid item xs={11} sx={{ margin: '1',}}>
                            <TextField sx={{ width:"100%"}} inputRef={inputRef} multiline color="primary" fullWidth rows={1} placeholder="Mensaje" onInput={handleInputRow}/>
                        </Grid>
                        <Grid item xs={1} sx={{ display: 'flex', flexDirection: 'column-reverse', justifyContent: 'top', alignItems: 'center' }}>
                            <Button
                                sx={{
                                    width: '100%',
                                    height: '56px',
                                    margin: '1%',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                                startIcon={<Send sx={{marginLeft: '13px'}}/>}
                                variant="contained"
                                size="large"
                                onClick={handleSendMessage}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Fragment>
    )

}

export default HelpCenterIndex;