import React, { Fragment, useEffect, useState, useRef } from "react";
import Menu from "../../Components/Menu/Menu";
import { Backdrop, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, IconButton, Paper, TextField, Tooltip, styled, Typography, Badge } from "@mui/material";
import FloatAlert from "../../Components/Alert";
import DataTable from "../../Components/DataTable/DataTable";
import {CheckOutlined, CloseOutlined, DownloadDoneOutlined, DownloadOutlined, Visibility,  Chat, Send, Refresh} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { OperationServices } from "../../Services/OperationService";
import dateTimeFormatter from "../../Utils/DateTimeFormatter";

const HelpCenterAdmin = () =>{

    const dispatch = useDispatch();
    let userId = Number(window.sessionStorage.getItem("userId"));
    const {name, numMessageUnread} = useSelector(store => store.userReducer);

    const initialValue = {
        id: 0,
        subject: "",
        userId: userId,
        comments: "",
        answer: "",
        status: 0
    }

    const initialChat = {
        ticketId: null,
        userSentId: userId,
        userSentRole: "admin",
        numMessagesUnread: 0,
        statusTicket: 0,
    }

    const [loading,setLoading] = useState(false);
    const [loadingBackDrop,setLoadingBackDrop] = useState(false);
    const [open,setOpen] = useState(false);
    const [value,setValue] = useState(initialValue);
    const [btnUploadText, setBtnUploadText] = useState("Cargar archivos");
    const [files,setFiles] = useState(null);
    const [chipData, setChipData] = React.useState([]);
    const [openChat, setOpenChat] = useState(false);
    const [refreshChat, setRefreshChat] = useState(false);
    const [valueChat, setValueChat] = useState(initialChat);
    const [messages, setMessages] = useState([]);
    const [tickets, setTickets] = useState([]);
    const [messagesUnread, setMessagesUnread] = useState([]);
    const inputRef = useRef(null);
    const messagesEndRef = useRef(null);

    useEffect(() =>{
        getHelpCenterTickets();
    },[])

    useEffect(() => {
        if (openChat || refreshChat) {
            changeStatusMessage();
            scrollToBottom();
            if (refreshChat){
                setRefreshChat(false);
            }
        }
    }, [messages, openChat, refreshChat]);

    const getHelpCenterTickets = () =>{
        setLoadingBackDrop(true);
        let params = {
            filter: `{"order": "creationDate DESC","include":["user"]}`
        }
        OperationServices.getHelpCenterTickets(params, (response,error) =>{
            setLoadingBackDrop(false);
            if(response?.length){
                setTickets(response);
                handleMessageUnread();
            }
        })
    }

    const handleMessageUnread = () => {
        OperationServices.getMessagesUnread({userId: userId}, (response, error) => {
            if (response?.success && !error) {
                setMessagesUnread(response.result.messagesTickets);
                dispatch({type: 'SET_MESSAGE_UNREAD', idx: 'numMessageUnread', value: response.result.messagesUnread});
            }else {
                setMessagesUnread([]);
                dispatch({type: 'SET_MESSAGE_UNREAD', idx: 'numMessageUnread', value: 0});
            }
        });
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'subjetc', headerName: 'Asunto', width: 200,
            valueGetter: (params) =>{
                return params.row.subject;
            },
        },
        { field: 'Fecha', headerName: 'Fecha', width: 170,
            valueGetter: (params) =>{
                return params.row.creationDate.substring(0,19).replace('T',' ');
                // return moment.tz(params.row.creationDate,'America/Mexico_City').format("YYYY-MM-DD HH:mm");
            },
        },
        { field: 'user', headerName: 'Usuario', width: 200,
            valueGetter: (params) =>{
                return params.row.user.email;
            },
        },
        
        { field: 'status', headerName: 'Estatus', width: 170,
            valueGetter: (params) =>{
                let status = "";
                switch(params.row.status){
                    case 1:
                        status = "PENDIENTE"
                        break;
                    case 2:
                        status = "EN REVISIÓN"
                        break;
                    case 4:
                        status = "SOLUCIONADO"
                        break;
                    case 0:
                        status = "CERRADO"
                        break;
                }
                return status
            },
        },
        { field: 'solvedBy', headerName: 'Solucionado Por', width: 200},
        { field: 'solvedDate', headerName: 'F. Solución', width: 200,
            valueGetter: (params) =>{
                return params.row.solvedDate?.replace('T', ' ').substring(0,16);
            },
        },
        {
            field: '',
            headerName: 'Opciones',
            description: 'This column has a value getter and is not sortable.',
            width: 160,
            renderCell: (cellValues) => {
                return (
                    <React.Fragment>
                         <Tooltip title="Revisar Ticket">
                            <IconButton aria-label="edit" 
                                onClick={(event) => {
                                    showDetails(event,cellValues);
                                }}
                                >
                                    <Visibility />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Chat">
                            <IconButton aria-label="chat" onClick={() => {handleOpenChat(cellValues);}}>
                                <Badge color="primary" badgeContent={messagesUnread.includes(parseInt(cellValues.row.id)) ? cellValues.row.numMessagesUnreadAdmin : 0} max={999} >
                                    <Chat />
                                </Badge>
                            </IconButton>
                        </Tooltip>
                    </React.Fragment>
                );
            }
            
        },
    ];

    const getClassName = (params) => {
        if (params.field === "status") {
          return params.value === "PENDIENTE" ? "cell-yellow" : params.value == 'EN REVISIÓN' ? "cell-orange" : "cell-green";
        }
        return "";
    }

    const solvedTicket = () =>{
        setLoading(true);
        let params = {
            id: value.id,
            body:{
                status: 4,
                answer: value.answer,
                solvedBy: name
            }
        }
        OperationServices.changeTicketStatus(params,(response,error) =>{
            setLoading(false);
            if(response && !error){
                let props = {open: true,
                    severity: 'success',
                    message: `Registro actualizado correctamente.`};
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
                handleClose();
                getHelpCenterTickets();
            }
            else{
                let props = {open: true,
                    severity: 'error',
                    message: error.message}
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
            }
        });
        

    };

    const handleClose = (e,reason) =>{
        if(!reason){
            setOpen(false);
            setValue(initialValue);
            setFiles(null);
            //setBtnUploadText("Cargar archivos")
            setChipData([]);
        }
    }

    const handleClickOpen = () =>{
        setOpen(true);
    }

    const showDetails = (e,cellValues) => {
        setValue({...value,id:cellValues.row.id, subject:cellValues.row.subject,comments: cellValues.row.comments,status: cellValues.row.status,answer: cellValues.row.answer});
        if(cellValues.row.status == 1){
            let params = {
                id: cellValues.row.id,
                body:{
                    status: 2
                }
            }
            OperationServices.changeTicketStatus(params,(response,error) =>{
                setValue({...value,id:cellValues.row.id, subject:cellValues.row.subject,comments: cellValues.row.comments,status: 2});
                getHelpCenterTickets();
            });
        }
        let chipArray = [];
        for (const file of cellValues.row.evidencePath) {
            let filePath = file.split('/');
            if(filePath.length > 1){
                let data = {
                    label: filePath[(filePath.length - 1)]
                }
                chipArray.push(data);
            }
            
        }
        //setBtnUploadText(target.files[0].name)
        
        
        setChipData(chipArray);
        setOpen(true);
        //setSolvedEvidence({...solvedEvidence,image: target.files[0]});
    };

    const ListItem = styled('li')(({ theme }) => ({
        margin: theme.spacing(0.5),
    }));

    const downloadEvidence = (evidenceName) =>{
        console.log(evidenceName);
        OperationServices.downloadTicketEvicence((evidenceName),(response) =>{
            var a = document.createElement('a');
            var url = window.URL.createObjectURL(response);
            a.href = url;
            a.download = evidenceName;
            document.body.append(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);

        })

        
    }

    const handleOpenChat = (ticket) => {
        setValueChat({...initialChat, ticketId: ticket.row.id, numMessagesUnread: ticket.row.numMessagesUnreadAdmin, statusTicket: ticket.row.status});
        setOpenChat(true);
        getMessages(ticket.row.id);
    };

    const handleCloseChat = () => {
        setOpenChat(false);
        setRefreshChat(false);
        setValueChat(initialChat);
        setMessages([]);
        inputRef.current.value = '';
        getHelpCenterTickets();
    };

    const getMessages = async (ticketId) => {
        let params = {
            filter: `{ "include":[{"relation":"userSent"}], "order": "creationDate ASC", "where": {"ticketId" : ${ticketId}}}`
        }
        OperationServices.getMessagesHelpCenterTicket(params, (response, error) =>{
            if(!error){
                setMessages(response || []);
            }
            else{
                let props = {
                    open: true,
                    severity: 'error',
                    message: error.message
                }
                dispatch({type:'UPDATE_ALERT_VALUES', idx:'alert', value:{props}});
            }
        })
    };

    const handleSendMessage = async () => {
        const message = inputRef.current.value;
        if (!message.trim()) {
            let props = {open: true,
                severity: 'error',
                message: 'El mensaje no puede estar vacío.'}
            dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
            return;
        }
        const newMessageData = {
            role: valueChat.userSentRole,
            body: {
                ticketId: valueChat.ticketId,
                content: message,
                userSentId: valueChat.userSentId,
            }
        }
        OperationServices.sendMessageHelpCenterTicket(newMessageData, (response, error) =>{
            if(response?.success && !error){
                inputRef.current.value = '';
                inputRef.current.style.height = 'auto';
                setMessages([...messages, {message: response.result, fullName: ""}]);
                if(valueChat.statusTicket === 1) {
                    let params = {
                        id: valueChat.ticketId,
                        body:{
                            status: 2
                        }
                    }
                    OperationServices.changeTicketStatus(params,(response,error) =>{
                        getHelpCenterTickets();
                    });
                }
            }else{
                let props = {open: true,
                    severity: 'error',
                    message: error?.message || response?.msg}
                dispatch({type:'UPDATE_ALERT_VALUES',idx:'alert',value:{props}});
            }
        });
    };

    const changeStatusMessage = () => {
        const unreadMessages = messages
            .filter(msg => !msg.message.isRead && msg.message.userSentId !== userId)
            .map(msg => msg.message.id);
        if (unreadMessages.length) {
            const updatedMessage = {
                role: valueChat.userSentRole,
                ticketId: valueChat.ticketId,
                body: {
                    messagesIds: unreadMessages
                }
            };
            OperationServices.changeStatusMessage(updatedMessage, (response, error) => {
                if (!response?.success && error) {
                    let props = {
                        open: true,
                        severity: 'error',
                        message: error.message
                    };
                    dispatch({ type: 'UPDATE_ALERT_VALUES', idx: 'alert', value: { props } });
                }else{
                    setMessages(prevMessages =>
                        prevMessages.map(msg => unreadMessages.includes(msg.message.id) ? { ...msg, message: { ...msg.message, isRead: true } } : msg)
                    );
                    handleMessageUnread();
                }
            });
        }
    };

    const handleInputRow = (e) => {
        const input = inputRef.current;
        if (input) {
            input.style.height = 'auto';
            const newHeight = Math.min(69, input.scrollHeight);
            input.style.height = `${newHeight}px`;
        }
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const handleRefreshChat = () => {
        setRefreshChat(true);
        getMessages(valueChat.ticketId);
    }

    const colors = [
        { hex: '#34B7F1', name: 'blue' },
        { hex: '#C0C0C0', name: 'chocolate' },
        { hex: '#FFA500', name: 'orange' },
        { hex: '#FFEB3B', name: 'darkturquoise' },
        { hex: '#A569BD', name: 'coral' },
        { hex: '#33FF8C', name: 'green' },
        { hex: '#FFC0CB', name: 'purple' },
        { hex: '#FF3333', name: 'red' },
        { hex: '#FF6347', name: 'cornflowerblue' },
        { hex: '#25D366', name: 'darkgrey' },
    ];
    const userColors = {};
    let colorIndex = 0;

    const assignColorToUser = (userId) => {
        if (!userColors[userId]) {
            userColors[userId] = colors[colorIndex % colors.length].name;
            colorIndex++;
        }
        return userColors[userId];
    };

    return(
        <Fragment>
            <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loadingBackDrop}
                >
                    <CircularProgress color="inherit" />
            </Backdrop>
            <FloatAlert/>
            <Menu permissionName={"Centro ayuda (Administrador)"}>
                <DataTable rows={tickets} columns={columns} getClassName={getClassName}/>
                <Box sx={{flexGrow: 1}}></Box>
                <Box sx={{ '& > :not(style)': { m: 1 } }}>
                        <Fab  color="primary" aria-label="add" onClick={() =>{getHelpCenterTickets()}}
                            sx={{position: 'fixed',bottom: 16, right: 16,}}>
                            <Tooltip title="Actualizar">
                                <Refresh />
                            </Tooltip>
                        </Fab>
                </Box>
            </Menu>

            <Dialog disableEscapeKeyDown={true} open={open} onClose={handleClose}>
                <DialogTitle>Ticket</DialogTitle>
                <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <TextField fullWidth variant="standard" inputProps={{readOnly: true}} required label="Asunto" value={value.subject} onChange={(e) =>{setValue({...value,subject:e.target.value})}}/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField multiline rows={2} fullWidth inputProps={{readOnly: true}} variant="standard" required label="Comentarios" value={value.comments} onChange={(e) =>{setValue({...value,comments:e.target.value})}}/>
                    </Grid>
                    {
                        chipData.length ? 
                        <Grid item xs={12} sm={12}>
                            <Paper
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    listStyle: 'none',
                                    p: 0.5,
                                    m: 0,
                                }}
                                component="ul"
                                >
                                {chipData.map((data,i) => {
                                    let icon;

                                    return (
                                    <ListItem key={i}>
                                        <Chip
                                            icon={<DownloadOutlined/>}
                                            label={data.label}
                                            onClick={() =>{downloadEvidence(data.label)}}
                                        />
                                    </ListItem>
                                    );
                                })}
                            </Paper>
                        </Grid>
                        : ""
                    }
                    <Grid item xs={12} sm={12}>
                        <TextField multiline rows={2} fullWidth variant="standard" inputProps={{readOnly: !value.status == 2}} required label="Solución" value={value.answer} onChange={(e) =>{setValue({...value,answer:e.target.value})}}/>
                    </Grid>
                </Grid>
                </DialogContent>
                <DialogActions>
                <Button disabled={loading} startIcon={<CloseOutlined />} onClick={handleClose} size="small" variant="outlined">Cancelar</Button>
                {
                    value.status == 2 ?
                    <LoadingButton
                        color="success"
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<CheckOutlined />}
                        variant="outlined"
                        loadingIndicator={<CircularProgress color="success" size={16}/>}
                        size="small"
                        disabled={!value.answer.length}
                        onClick={solvedTicket}
                    >
                        Solucionado
                    </LoadingButton>
                    : ""
                }
                
                </DialogActions>
            </Dialog>
            <Dialog disableEscapeKeyDown={true} open={openChat} onClose={handleCloseChat} maxWidth="sm" fullWidth PaperProps={{sx: {maxWidth: '70%', width: '700px', margin: 'auto',}}}>
                <Grid container spacing={3}>
                    <Grid item sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems:"center"}}>
                        <DialogTitle>Chat de ayuda</DialogTitle>
                        <DialogActions>
                            <Button disabled={loading} startIcon={<CloseOutlined color="error"/>} color="error" onClick={handleCloseChat} size="small" variant="outlined">Cerrar</Button>
                            <LoadingButton
                                loading={loading}
                                loadingPosition="start"
                                startIcon={<Refresh />}
                                variant="outlined"
                                loadingIndicator={<CircularProgress color="primary" size={16}/>}
                                size="small"
                                onClick={() => {handleRefreshChat()}}
                            >
                                Refrescar
                            </LoadingButton>
                        </DialogActions>
                    </Grid>
                </Grid>
                <DialogContent sx={{
                    height: '400px',
                    maxHeight: '70%',
                    overflowY: 'scroll',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'normal',
                    borderRadius: '9px',
                    borderTop: '1px solid #ccc',
                    borderBottom: '1px solid #ccc',
                    bgcolor: '#ededed',
                }}>
                    {messages.map((msg, index) => (
                        <Box
                            key={index}
                            sx={{
                                maxWidth: '100%',
                                display: 'flex',
                                flexDirection: msg.message.userSentId === userId ? 'row-reverse' : 'row',
                                mb: 1,
                            }}
                        >
                            <Box
                                sx={{
                                maxWidth: '50%',
                                padding: '8px 12px',
                                borderTopLeftRadius:  msg.message.userSentId === userId ? '12px' : '0',
                                borderTopRightRadius: msg.message.userSentId === userId ? '0' : '12px',
                                borderBottomLeftRadius: '12px',
                                borderBottomRightRadius: '12px',
                                bgcolor: msg.message.userSentId === userId ? 'primary.light' : '#ffffff',
                                color: 'black',
                                textAlign: "start",
                                boxShadow: '2px 5px 4px 0.5px rgba(0,0,0,0.15)',
                                }}
                            >
                                <Typography variant="caption" sx={{
                                    font: "550 0.8em 'Roboto', sans-serif",
                                    color: msg.message.userSentId === userId ? '' : assignColorToUser(msg.fullName ?? msg.message.userSent.email.split('@')[0]),
                                }}>
                                    {msg.message.userSentId === userId ? "" : msg.fullName ?? msg.message.userSent.email.split('@')[0]}
                                </Typography>
                                <Typography variant="body2" sx={{
                                    whiteSpace: 'pre-wrap',
                                    wordWrap: 'break-word',
                                    font: "500 1.03em 'Roboto', sans-serif",
                                    }}>
                                    {msg.message.content}
                                </Typography>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', paddingTop: 1}}>
                                    <Typography variant="caption" sx={{font: "550 0.6em 'Roboto', sans-serif", color:'darkslategray'}}>{dateTimeFormatter(msg.message.creationDate, '/')}</Typography>
                                    {msg.message.userSentId == userId && msg.message.isRead ? <CheckOutlined sx={{color: 'green', fontSize: '0.9em', marginLeft: 1}}/> : msg.message.userSentId != userId ? "" : <CheckOutlined sx={{color: 'white', fontSize: '0.9em', marginLeft: 1}}/>}
                                </Box>
                            </Box>
                        </Box>
                    ))}
                    <div ref={messagesEndRef} />
                </DialogContent>
                <DialogContent >
                    <Grid container spacing={2} sx={{ padding: '0', }}>
                        <Grid item xs={11} sx={{ margin: '1',}}>
                            <TextField sx={{ width:"100%"}} inputRef={inputRef} multiline color="primary" fullWidth rows={1} placeholder="Mensaje" onInput={handleInputRow}/>
                        </Grid>
                        <Grid item xs={1} sx={{ display: 'flex', flexDirection: 'column-reverse', justifyContent: 'top', alignItems: 'center' }}>
                            <Button
                                sx={{
                                    width: '100%',
                                    height: '56px',
                                    margin: '1%',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                                startIcon={<Send sx={{marginLeft: '13px'}}/>}
                                variant="contained"
                                size="large"
                                onClick={handleSendMessage}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Fragment>
    )

}

export default HelpCenterAdmin;