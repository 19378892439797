import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import FloatAlert from "../../Components/Alert";
import { React, useState, useEffect } from "react";
import MenuComponent from "../../Components/Menu/Menu";
import DataTable from "../../Components/DataTable/DataTable";
import { ParameterConfigService } from "../../Services/ParamConfigService";
import { Add, CheckBoxOutlineBlankOutlined, CheckBoxOutlined, CloseOutlined, Delete, EditOutlined, Save } from "@mui/icons-material";
import { Autocomplete, Backdrop, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControlLabel, Grid, IconButton, TextField, Tooltip } from "@mui/material";

export const ParameterConfig = () => {
    const [currentUserConfParams, setCurrentUserConfParams] = useState([])
    const [selectedConfig, setSelectedConfig] = useState(null);
    const [configOptions, setConfigOptions] = useState([]);
    const [saveAttempt, setSaveAttempt] = useState(false);
    const [configValue, setConfigValue] = useState('');
    const [inputType, setInputType] = useState("text");
    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState(false);
    const [currentTempId, setCurrentTempId] = useState(0);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        getAllConfigParams()
        getAllUserConfigParams()
    }, [])

    const getAllUserConfigParams = () => {
        // ParameterConfigService.findParameterConfigsByUserId(parseInt(sessionStorage.getItem('userId')), (response, error) => {
        //     setCurrentUserConfParams(response)
        // })
        ParameterConfigService.findParameterConfigs((response, error) => {
            setCurrentUserConfParams(response)
        })
    }

    const getAllConfigParams = () => {
        ParameterConfigService.getStaticParameterConfigs((response, error) => {
            setConfigOptions(response)
        })
    }

    const getInfoParameterConfigById = (id, callback) => {
        ParameterConfigService.getInfoParameterConfigById(id, (response, error) => {
            if (response?.status === 200){
                setAlertMessage("success", response.message);
                callback(response);
            } else {
                setAlertMessage("error", error.message);
                callback(null);
            }
        });
    };

    const patchStatusParameterConfigById = (event, cellValues) => {
        ParameterConfigService.patchParameterConfigById(cellValues.id, {status: Number(!cellValues?.row?.status)}, (response, error) => {
            if (response?.status === 200){
                setAlertMessage("success", response.message)
                getAllUserConfigParams()
            } else
                setAlertMessage("error", error.message)
        })
    }

    const patchValueParameterConfigById = (id, data) => {
        ParameterConfigService.patchParameterConfigById(id, data, (response, error) => {
            if (response?.status === 200){
                setAlertMessage("success", response.message)
                getAllUserConfigParams()
            } else
                setAlertMessage("error", error.message)
        })
    }

    const deleteParameterConfigById = (event, cellValues) => {
        ParameterConfigService.deleteParameterConfigById(cellValues.id, (response, error) => {
            if (response?.status === 200){
                setAlertMessage("success", response.message)
                getAllUserConfigParams()
            } else
                setAlertMessage("error", error.message)
        })
    }

    const postOneConfigParamByUserId = (data) => {
        ParameterConfigService.createParameterConfig(data, (response, error) => {
            if (response?.status === 201 || response?.status === 200) {
                setAlertMessage("success", response.message)
                getAllUserConfigParams()
            } else {
                setAlertMessage("error", error.message)
            }
        })
    }

    const editConfigParam = (event, cellValues) => {
        getInfoParameterConfigById(cellValues.id, (data) => {
            setEditing(true)
            setOpen(true)
            setCurrentTempId(data.data.id)
            handleConfigChange(event, {name: data.data.name, prefix: data.data.prefix, type: data.data.type})
            setConfigValue(data.data.value)
        });
    }

    const setAlertMessage = (severity, message) => {
        dispatch({
            type: 'UPDATE_ALERT_VALUES',
            idx: 'alert',
            value: {
                props: {
                    open: true,
                    severity: severity,
                    message: message
                }
            }
        });
    }

    const getClassName = (params) => {
        if (params.field === "status")
            return params.value === "ACTIVO" ? "active-cell" : "inactive-cell";
        return "";
    }

    const dateOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        {
            field: 'parameter name', headerName: 'Nombre de Parámetro', width: 250,
            valueGetter: (params) => {
                return params.row.name;
            },
        },
        {
            field: 'current value', headerName: 'Valor del parámetro', width: 150,
            valueGetter: (params) => {
                return params.row.value;
            },
        },
        {
            field: 'last update', headerName: 'Última actualización', width: 250,
            valueGetter: (params) => {
                // return new Date(params.row.creationDate).toLocaleString("es-ES", dateOptions);
                return params.row.creationDate.substring(0, 19).replace("T", " ")
            },
        },
        {
            field: 'status', headerName: 'Estatus', width: 130,
            valueGetter: (params) => {
                return params.row.status === 1 ? "ACTIVO" : "INACTIVO";
            },
        },
        {
            field: '',
            headerName: 'Opciones',
            description: 'This column has a value getter and is not sortable.',
            width: 160,
            renderCell: (cellValues) => {
                return (
                    <>
                        <Tooltip title="Editar">
                            <IconButton aria-label="edit"
                                onClick={(event) => {
                                    // putParameterConfigById(cellValues)
                                    editConfigParam(event, cellValues)
                                }}
                            >
                                <EditOutlined />
                            </IconButton>
                        </Tooltip>
                        {
                            cellValues.row.status === 0 ?
                                <Tooltip title="Habilitar">
                                    <IconButton aria-label="Habilitar"
                                        onClick={(event) => {
                                            patchStatusParameterConfigById(event, cellValues)
                                        }}
                                    >
                                        < CheckBoxOutlineBlankOutlined />
                                    </IconButton>
                                </Tooltip>
                                :
                                <Tooltip title="Deshabilitar">
                                    <IconButton aria-label="Deshabilitar"
                                        onClick={(event) => {
                                            patchStatusParameterConfigById(event, cellValues)
                                        }}
                                    >
                                        < CheckBoxOutlined />
                                    </IconButton>
                                </Tooltip>
                        }
                        {/* <Tooltip title="Eliminar">
                            <IconButton aria-label="delete"
                                onClick={(event) => {
                                    deleteParameterConfigById(event, cellValues)
                                }}
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip> */}
                    </>
                );
            }

        },
    ];

    const handleClickOpen = () => {
        setOpen(true);
        setEditing(false);
        getAllConfigParams()
        setSelectedConfig(null)
        setConfigValue("")
        setInputType("text")
    }

    const handleClose = (e, reason) => {
        if (!reason) {
            setSaveAttempt(false);
            setOpen(false);
        }
    }

    const handleSave = () => {
        setSaveAttempt(true);
        if (selectedConfig && configValue || selectedConfig && selectedConfig.type == "boolean") {
            const convertedValue = convertValue(configValue, inputType);
            let dataToSend = {
                ...selectedConfig,
                value: convertedValue,
                // creationDate: new Date().toISOString(),
                userId: parseInt(sessionStorage.getItem('userId'))
            };
            setLoading(true);
            if (!editing)
                postOneConfigParamByUserId(dataToSend)
            else
                patchValueParameterConfigById(currentTempId, dataToSend)
            setLoading(false);
            handleClose();
        } else {
            dispatch({
                type: 'UPDATE_ALERT_VALUES',
                idx: 'alert',
                value: {
                    props: {
                        open: true,
                        severity: 'error',
                        message: 'Es necesario elegir una configuración y escribir un valor.'
                    }
                }
            });
        }
    };

    const handleConfigChange = (event, newValue) => {
        setSelectedConfig(newValue);
        setConfigValue("")
        if (newValue) {
            switch (newValue.type) {
                case "number":
                    setInputType("number");
                    break;
                case "boolean":
                    setInputType("checkbox");
                    break;
                case "string":
                default:
                    setInputType("text");
                    break;
            }
        } else {
            setInputType("text");
        }
    };

    const convertValue = (value, type) => {
        switch (type) {
            case "number":
                return Number(value);
            case "checkbox":
                return !!value;
            default:
                return value;
        }
    };

    return (
        <>
            <FloatAlert />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <FloatAlert />
            <MenuComponent permissionName={"Configuración de Parámetros"}>
                <DataTable columns={columns} rows={currentUserConfParams} getClassName={getClassName} />
                <Box sx={{ flexGrow: 1 }}></Box>
                <Box sx={{ '& > :not(style)': { m: 1 } }}>
                    <Fab color="primary" aria-label="add" onClick={() => { (handleClickOpen()) }}
                        sx={{ position: 'absolute', bottom: 16, right: 16, }}>
                        <Add />
                    </Fab>

                </Box>
            </MenuComponent>
            <Dialog disableEscapeKeyDown={true} open={open} onClose={handleClose}>
                <DialogTitle>{(editing ? "Actualizar" : "Configurar Nuevo") + " Parámetro"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Autocomplete
                                disablePortal
                                options={configOptions}
                                getOptionLabel={(option) => option?.name || ''}
                                value={selectedConfig}
                                isOptionEqualToValue={(option, value) =>
                                    option.name === value?.name
                                }
                                disabled={editing}
                                onChange={handleConfigChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Configuración a elegir"
                                        required
                                        variant="standard"
                                        helperText={saveAttempt && !selectedConfig ? "Es requerido" : ""}
                                        error={saveAttempt && !selectedConfig}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {inputType === "checkbox" ? (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={!!configValue}
                                            onChange={(e) => setConfigValue(e.target.checked)}
                                        />
                                    }
                                    label="Valor"
                                />
                            ) : (
                                <TextField
                                    fullWidth
                                    label="Valor"
                                    variant="standard"
                                    required
                                    value={configValue}
                                    onChange={(e) => setConfigValue(e.target.value)}
                                    helperText={saveAttempt && !configValue ? "Es requerido" : ""}
                                    error={saveAttempt && !configValue}
                                    type={inputType}
                                />
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} startIcon={<CloseOutlined />} onClick={handleClose} size="small" variant="outlined">Cancelar</Button>
                    <LoadingButton
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<Save />}
                        variant="outlined"
                        loadingIndicator={<CircularProgress color="primary" size={16} />}
                        size="small"
                        onClick={handleSave}
                    >
                        Guardar
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}