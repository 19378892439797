import API_ROUTES from "../Routes/APIRoutes";


export const InvoiceService = {
    getInvoicesByProvider: (params,callback) =>{
        let filter = params.filter;
        fetch(API_ROUTES.getInvoicesByProvider+`?filter=${encodeURI(filter)}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                callback(null,response.error);
                else
                    callback(response);
                })
                
            })
        .catch((err)=>callback(null, err));
    },
    getInvoiceDetails: (invoiceId,callback) =>{
        //let filter = params.filter;
        fetch(API_ROUTES.getInvoiceDetails.replace('{id}',invoiceId), {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                callback(null,response.error);
                else
                    callback(response);
                })
                
            })
        .catch((err)=>callback(null, err));
    },
    sendToSap: (params,callback) =>{
        fetch(API_ROUTES.sendToSap, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
            body: JSON.stringify(params)
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                    callback(null,response.error);
                else
                    callback(response);
                })
                
            })
        .catch((err)=>callback(null, err));
    },
    uploadInvoiceComplement: (formData,sapProviderId, callback) => {
        fetch(API_ROUTES.uploadInvoiceComplement + sapProviderId,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
            body: formData 
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                    callback(null,response.error);
                else
                    callback(response);
            })
            
        })
        .catch((err)=>callback(null, err));
    },
    getInvoiceComplements: (params,callback) =>{
        let filter = params.filter;
        fetch(API_ROUTES.getInvoiceComplements+`?filter=${encodeURI(filter)}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                callback(null,response.error);
                else
                    callback(response);
                })
                
            })
        .catch((err)=>callback(null, err));
    },
    getComplementDetails: (complementId,callback) =>{
        //let filter = params.filter;
        fetch(API_ROUTES.getComplementDetails.replace('{id}',complementId), {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                callback(null,response.error);
                else
                    callback(response);
                })
                
            })
        .catch((err)=>callback(null, err));
    },
    getPayments: (params,callback) => {
        fetch(API_ROUTES.getPayments,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.sessionStorage.getItem("usrToken")}`,
            },
            body: JSON.stringify(params) 
        })
        .then(res =>{
            res.json().then(response =>{
                if(response.error)
                    callback(null,response.error);
                else
                    callback(response);
            })
            
        })
        .catch((err)=>callback(null, err));
    },
}